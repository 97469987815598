@if ({
  images: images$ | async,
  editPermission: canEdit$ | async,
  editMode: editMode$ | async,
  configuration: configuration$ | async,
  imageParent: imageParent$ | async
  }; as obs) {
  @if (obs.images?.length > 0) {
    <h2 class="header">
      @if (obs.images.length === 1) {
        <span>{{obs.images[0].name}}</span>
      } @else {
        <em>{{obs.images.length}} {{obs.images.length | i18nPlural: imagesPluralMapping}} ausgewählt.</em>
      }
      @if (obs.configuration.enableCloseButton) {
        <button
          mat-icon-button
          class="close-button"
          (click)="imageSidebar.unsetImages()"
          matTooltip="Aufnahmeseitenleiste schließen"
          >
          <mat-icon>close</mat-icon>
        </button>
      }
    </h2>
    <div class="subheader">
      <div class="file-info-container">
        <ul class="file-infos inline">
          @if (obs.images.length === 1) {
            <li class="inline">
              <span matTooltip="Interne ID"><mat-icon inline fontSet="material-icons-outlined">info</mat-icon></span>images/{{obs.images[0].id}}
            </li>
          }
          @if (obs.images.length === 1) {
            <li class="inline">
              <span matTooltip="Angelegt am"><mat-icon inline fontSet="material-icons-outlined">flare</mat-icon></span>{{obs.images[0].created_at | date}}
            </li>
          }
          @if (obs.images.length === 1) {
            <li class="inline">
              <span matTooltip="Zuletzt bearbeitet"><mat-icon inline fontSet="material-icons-outlined">edit</mat-icon></span>{{obs.images[0].updated_at | date}}
            </li>
          }
        </ul>
        <ul class="file-infos">
          <li>
            <span [matTooltip]="obs.images.length === 1 ? 'Dateiname und -größe' : 'Gesamtgröße der Bilddateien'"><mat-icon inline fontSet="material-icons-outlined">insert_drive_file</mat-icon></span>
            @if (obs.images.length === 1) {
              <span>{{ obs.images[0].image_file?.filename }}</span>,
            }
            {{ getTotalSize(obs.images) | formatBytes }}
          </li>
          @if (obs.images.length === 1) {
            <li>
              <span matTooltip="Auflösung"><mat-icon inline>aspect_ratio</mat-icon></span>@if (obs.images[0].dimensions; as dimensions) {
              <span>
                {{ dimensions[0] }}x{{ dimensions[1] }}
              </span>
            }
          </li>
        }
      </ul>
    </div>
    <div class="action-buttons">
      <div
        class="action-button"
        role="button"
        matRipple
        matTooltip="Ausgewählte Aufnahmen herunterladen">
        <insc-image-download-menu #imageDownloadMenu
          [images]="obs.images"
          [parent]="obs.imageParent"
        ></insc-image-download-menu>
        <a class="download-link" [matMenuTriggerFor]="imageDownloadMenu.menu"><mat-icon>file_download</mat-icon></a>
      </div>
      @if (obs.images?.length > 1) {
        <div
          class="action-button"
          role="button"
          matRipple
          matTooltip="Galerieansicht öffnen"
          (click)="imageViewer.open('Ausgewählte Aufnahmen', obs.images)"
          >
          <mat-icon>collections</mat-icon>
        </div>
      }
      @if (obs.images?.length === 1) {
        <div
          class="action-button"
          role="button"
          matRipple
          matTooltip="Vollbildansicht öffnen"
          (click)="imageViewer.open(obs.images[0].name, obs.images)"
          >
          <mat-icon>open_in_full</mat-icon>
        </div>
      }
      <div
        class="action-button"
        role="button"
        matRipple
        matTooltip="Weitere Aktionen"
        [matMenuTriggerFor]="moreMenu"
        >
        <mat-icon>more_vert</mat-icon>
      </div>
      <mat-menu #moreMenu>
        @if (obs.configuration.enableDelete) {
          <button mat-menu-item (click)="delete(obs.images)">
            <mat-icon>delete</mat-icon>
            <span>Löschen...</span>
          </button>
        }
        <button mat-menu-item routerLink="/admin/activities" [queryParams]="{item_type: 'Image', item_id: getActivityItemIds(obs.images)}">
          <mat-icon>assignment</mat-icon>
          <span>Aktivitätsprotokoll...</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <insc-image-viewer class="image-preview" [enableRotation]="false" [showImageTitle]="false" [images]="obs.images" fallbackImageMode="pyramid" [collectionMode]="true"></insc-image-viewer>
  <mat-tab-group class="sidebar-tabs">
    <mat-tab label="Metadaten" [disabled]="obs.editMode">
      <div class="tab-content">
        @if (obs.editPermission) {
          <div class="buttons-space-between">
            @if (!obs.editMode) {
              <button color="primary" mat-button (click)="setEditMode(true)">
                <mat-icon>edit</mat-icon>
                <span>Bearbeiten</span>
              </button>
            } @else {
              <button mat-button (click)="cancel()" color="warn">
                <mat-icon>close</mat-icon>
                <span>Bearbeitung abbrechen</span>
              </button>
              @if (obs.editMode) {
                <button mat-button color="primary" (click)="openMetadataPicker()" matTooltip="Aus bestehendem Datensatz übernehmen">
                  <mat-icon>keyboard_tab</mat-icon>
                  <span>Übernehmen...</span>
                </button>
              }
            }
          </div>
        }
        <insc-multi-image-editor class="metadata-content" #metadataMultiView [editable]="obs.editMode" [images]="obs.images" (hasChanges)="onHasChangesChanged($event)"></insc-multi-image-editor>
        @if (obs.editMode) {
          <div class="buttons-space-between">
            <button mat-button (click)="save(metadataMultiView.getUpdates())" color="primary" [disabled]="!imageSidebar.hasUnsavedChanges">
              <mat-icon>save</mat-icon>
              <span>Speichern</span>
            </button>
            @if (imageSidebar.hasUnsavedChanges) {
              <em>Ungespeicherte Änderungen</em>
            }
            @if (!imageSidebar.hasUnsavedChanges) {
              <em>Keine Änderungen</em>
            }
          </div>
        }
      </div>
    </mat-tab>
    <mat-tab label="IPTC" [disabled]="obs.editMode">
      <div class="tab-content">
        @if (obs.editPermission) {
          <div class="buttons-space-between">
            @if (!obs.editMode) {
              <button color="primary" mat-button (click)="setEditMode(true)">
                <mat-icon>edit</mat-icon>
                <span>Bearbeiten</span>
              </button>
            } @else {
              <button mat-button (click)="cancel()" color="warn">
                <mat-icon>close</mat-icon>
                <span>Bearbeitung abbrechen</span>
              </button>
              <button mat-button (click)="openTableIptcEditor()" color="primary">
                <mat-icon>table_view</mat-icon>
                <span>Tabellarisch bearbeiten</span>
              </button>
            }
          </div>
        }
        <insc-multi-iptc-editor class="metadata-content" #iptcMultiView [images]="obs.images" [editable]="obs.editMode" (hasChanges)="onHasChangesChanged($event)"></insc-multi-iptc-editor>
        @if (obs.editMode) {
          <div class="buttons-space-between">
            <button mat-button (click)="save(iptcMultiView.getUpdates())" color="primary" [disabled]="!imageSidebar.hasUnsavedChanges">
              <mat-icon>save</mat-icon>
              <span>Speichern</span>
            </button>
            @if (imageSidebar.hasUnsavedChanges) {
              <em>Ungespeicherte Änderungen</em>
            }
            @if (!imageSidebar.hasUnsavedChanges) {
              <em>Keine Änderungen</em>
            }
          </div>
        }
      </div>
    </mat-tab>
    <mat-tab label="Dateiinfos" [disabled]="obs.editMode">
      @for (image of obs.images; track image) {
        <div class="file-attributes">
          <h4>{{ image.name }}</h4>
          <dl>
            <dt class="label">Dateiname</dt>
            <dd class="value">{{image.image_file?.filename}}</dd>
            <dt class="label">Größe</dt>
            <dd class="value">{{image.image_file?.size | formatBytes}}</dd>
            <dt class="label">Originalmetadaten</dt>
            <dd class="value"><a class="file-metadata-link" (click)="openFileMetadata(image)">öffnen</a></dd>
          </dl>
        </div>
      }
    </mat-tab>
  </mat-tab-group>
}
}
