import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { Observable, of, Subject } from "rxjs"
import { distinctUntilChanged, filter, switchMap, takeUntil } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service"
import { ImageService, InscriptionService } from "../../../services/data.service"
import { LinkingService } from "../../../services/linking.service"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"

import { InscImage, Inscription, ObjectLocation } from "../../../shared/models"
import { ImageDropEvent } from "../../images/image-management/image-management-group.component"
import { ImageManagementDirective, ImageSelection } from "../../images/image-management/image-management.directive"
import { ImageSidebarService } from "../../images/image-sidebar/image-sidebar.service"
import { ImageUploadDialogService } from "../../images/image-uploader/image-upload-dialog.service"
import { RecordPageStateService } from "../record-page-state.service"
import { LinkChooserDialogService } from "../shared/dialogs/link-chooser-dialog/link-chooser-dialog.component"

@Component({
  selector:    'insc-inscription-detail',
  templateUrl: './inscription-detail.component.html',
  styleUrls:   ['../record-detail-styles.scss', './inscription-detail.component.css']
})
export class InscriptionDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  hasEditPermission = this.auth.permission('update_records')

  private unsubscribe$ = new Subject<void>()

  get inscription(): Inscription {return this.recordPageState.record }

  @ViewChild(ImageManagementDirective) imageMgmt: ImageManagementDirective

  constructor(
    private recordPageState: RecordPageStateService<Inscription>,
    private inscriptionService: InscriptionService,
    private auth: AuthService,
    private imageSidebarService: ImageSidebarService,
    private reusableDialogs: ReusableDialogsService,
    private imageUploadDialog: ImageUploadDialogService,
    private linkChooserDialogService: LinkChooserDialogService,
    private linkingService: LinkingService
  ) { }

  ngOnInit(): void {
    this.imageSidebarService.afterSave$.pipe(
      switchMap(_ => this.inscriptionService.get(this.inscription.id)),
      takeUntil(this.unsubscribe$)
    ).subscribe(inscription => this.recordPageState.record = inscription)

    this.recordPageState.recordChanges.pipe(
      distinctUntilChanged((a, b) => {
        if (a && b && a.id === b.id) {
          return true
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(_ => this.imageMgmt?.clear())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  canDeactivate(): Observable<boolean> | true {
    return this.imageSidebarService.hasUnsavedChanges
      ? this.reusableDialogs.openUnsavedChangesConfirmationDialog().afterClosed()
      : true
  }

  openImageUploader(): void {
    this.imageUploadDialog.open({
      parent: { type: "inscription", id: this.inscription.id, isContext: false},
      linkedRecordName: this.inscription.name
    }).afterClosed().pipe(
      filter(Boolean),
      switchMap(() => this.inscriptionService.get(this.inscription.id))
    ).subscribe(inscription => this.recordPageState.record = inscription)
  }

  linkImages(): void {
    const currentImageIds = this.inscription.images.map(image => image.id)
    this.linkChooserDialogService.open({
      dataService: ImageService,
      select: currentImageIds,
      config: {
        title: "Aufnahmen mit Inschrift verknüpfen"
      }
    }).afterClosed().pipe(
      filter(result => result != null),
      switchMap(result =>  result.selection.length > 0
                           ? this.linkingService.move("image", result.selection, "inscription", this.inscription.id, "images")
                           : this.linkingService.unlink("image", currentImageIds, "inscription", this.inscription.id, "images")),
      switchMap(() => this.inscriptionService.get(this.inscription.id)))
      .subscribe(inscription => this.recordPageState.record = inscription)
  }

  unlinkImages(imageIds: string[]): void {
    this.linkingService
      .unlink("image", imageIds, "inscription", this.inscription.id, "images")
      .pipe(switchMap(() => this.inscriptionService.get(this.inscription.id)))
      .subscribe(inscription => this.recordPageState.record = inscription)
  }

  onImageDrop(dropEvent: ImageDropEvent): void {
    const position = dropEvent.index + 1
    this.linkingService
      .moveFrom("image", dropEvent.image.id, "inscription", this.inscription.id, "images", "inscription", this.inscription.id, "images", { position })
      .pipe(switchMap(() => this.inscriptionService.get(this.inscription.id)))
      .subscribe({
        next: inscription => this.recordPageState.record = inscription,
        error: () => this.inscriptionService.get(this.inscription.id)
      })
  }


  onImageSelectionChange(selection: ImageSelection[]): void {
    const selectedImages = this.inscription.images.filter(image => selection.includes(image.id))
    this.imageSidebarService.setImages(selectedImages, {enableDelete: false, enableCloseButton: false}, {type: "inscription", id: this.inscription.id})
  }

  canChangeImageSelection: () => (Observable<boolean>) = () => {
    if (this.imageSidebarService.hasUnsavedChanges) {
      return this.reusableDialogs.openUnsavedChangesConfirmationDialog().afterClosed()
    }

    return of(true)
  }

  selectedImages(selection: ImageSelection[]): InscImage[] {
    return this.inscription.images.filter(image => selection.includes(image.id))
  }

  distinctLocations(locations: ObjectLocation[]) {
    return locations.filter((location, index, locations) =>
      locations.findIndex(_location => _location.id === location.id) === index
    )
  }
}
