import { DragDropModule } from "@angular/cdk/drag-drop"
import { OverlayModule } from "@angular/cdk/overlay"
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms"
import { MatAutocompleteModule } from "@angular/material/autocomplete"
import { MatBadgeModule } from "@angular/material/badge"
import { MatButtonModule } from "@angular/material/button"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatChipsModule } from "@angular/material/chips"
import { MatDialogModule } from "@angular/material/dialog"
import { MatIconModule } from "@angular/material/icon"
import { MatInputModule } from "@angular/material/input"
import { MatMenuModule } from "@angular/material/menu"
import { MatPaginatorModule } from "@angular/material/paginator"
import { MatSelectModule } from "@angular/material/select"
import { MatSortModule } from "@angular/material/sort"
import { MatTableModule } from "@angular/material/table"
import { MatTooltipModule } from "@angular/material/tooltip"
import {
  VocabularyFormComponentsModule
} from "../../../shared/components/vocabularies/vocabulary-form-components/vocabulary-form-components.module"
import { RecordingDatePickerComponent } from "../../../shared/components/./form-components/recording-date-picker/recording-date-picker.component"
import { IptcFormFieldWrapperComponent } from "./multi-iptc-editor/iptc-form-field-wrapper-component/iptc-form-field-wrapper.component"
import { MultiFieldComponent, MultiFieldReadonlyViewDirective } from "./shared/multi-field/multi-field.component"
import {
  MultiGroupComponent,
  MultiGroupInnerControlDirective,
  MultiGroupReadonlyViewDirective
} from "./shared/multi-group/multi-group.component"
import {
  MultiFieldAccessoryDirective,
  MultiGroupAccessoryDirective,
  MultiImageEditorComponent
} from "././multi-image-editor/multi-image-editor.component"
import { RecordingDateDialogComponent } from "././multi-image-editor/recording-date-dialog.component"
import { MultiIptcEditorComponent } from "./multi-iptc-editor/multi-iptc-editor.component"
import { TableIptcEditorDialogComponent } from "./table-iptc-editor/table-iptc-editor-dialog.component"
import { TableIptcEditorComponent } from "./table-iptc-editor/table-iptc-editor.component"


@NgModule({
  declarations: [
    MultiFieldComponent,
    MultiGroupInnerControlDirective,
    MultiGroupReadonlyViewDirective,
    MultiFieldReadonlyViewDirective,
    MultiGroupComponent,
    MultiImageEditorComponent,
    MultiFieldAccessoryDirective,
    MultiGroupAccessoryDirective,
    RecordingDateDialogComponent,
    IptcFormFieldWrapperComponent,
    MultiIptcEditorComponent,
    TableIptcEditorComponent,
    TableIptcEditorDialogComponent
  ],
  exports: [
    MultiImageEditorComponent,
    MultiIptcEditorComponent,
    TableIptcEditorComponent,
    MultiFieldAccessoryDirective,
    MultiGroupAccessoryDirective,
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatIconModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    OverlayModule,
    MatDialogModule,
    MatBadgeModule,
    DragDropModule,
    MatTableModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSortModule,
    MatPaginatorModule,
    VocabularyFormComponentsModule,
    RecordingDatePickerComponent,
  ]
})
export class ImageMetadataEditorsModule { }
