<!-- BEGIN TEMPLATES -->

<!-- Suffix button to reset the value to the last saved value -->
<ng-template #resetButton let-formControlName="formControlName">
  @if (editable && formGroup.get(formControlName).dirty) {
    <button mat-icon-button
      (click)="reset(formControlName)"
      matTooltip="Änderungen zurücksetzen">
      <mat-icon>undo</mat-icon>
    </button>
  }
</ng-template>

<!-- prefix icon to show whether a value has been set manually or was generated and left untouched -->
<ng-template #iptcFieldStateIcon let-iptcControl="iptcControl">
  @if (iptcControl.state === 'generatedValue') {
    <mat-icon
      fontSet="material-icons-outlined"
      class="compact-prefix"
      matTooltip="Generierter Wert: dieser Wert wurde anhand der Metadaten automatisch erzeugt."
    >auto_fix_normal</mat-icon>
  }

  @if (iptcControl.state === 'manualValue') {
    <mat-icon
      class="compact-prefix manual"
      matTooltip="Manuell festgeleget Wert."
    >🅼</mat-icon>
  }
</ng-template>

<!-- prefix icon to reset a manually set value to the automatically generated one -->
<ng-template #resetToGeneratedButton let-iptcControl="iptcControl">
  @if (iptcControl.state !== 'generatedValue' && iptcControl.iptcValue?.generated) {
    <button
      mat-icon-button
      (click)="iptcControl.resetToGenerated(); $event.stopPropagation()"
      matTooltip="Automatisch generierten Wert wiederherstellen: {{ iptcControl.iptcValue.generated }}"
      ><mat-icon>auto_fix_high</mat-icon></button>
    }
  </ng-template>

  <!-- END TEMPLATES -->


  <!-- BEGIN CONTENT -->
  <div class="container" [formGroup]="formGroup">
    <!-- template for an IPTC field for editing a string value -->
    <!-- depends on formGroup directive above -->
    <ng-template #textField let-formControlName="formControlName">
      <insc-multi-field
        [editable]="editable"
        [multiRecordFormControl]="getMultiControl(formControlName)"
        [getDisplayValue]="getIptcTextFieldDisplayValue"
        >
        <insc-iptc-form-field-wrapper [formControlName]="formControlName" #iptcControl>
          <mat-form-field [ngClass]="getFieldClass(iptcControl.state)">

            <div matPrefix>
              <ng-container *ngTemplateOutlet="iptcFieldStateIcon; context: {iptcControl}"></ng-container>
            </div>

            <mat-autocomplete #autocomplete="matAutocomplete">
              @if (iptcControl.iptcValue?.generated) {
                @if (iptcControl.state !== 'generatedValue') {
                  <mat-option>
                    <div>
                      <div class="suggestion-header">Aus Metadaten generierter Vorschlag</div>
                      <div class="suggestion-value">
                        <mat-icon inline>auto_fix_high</mat-icon>
                        <span>{{iptcControl.iptcValue.generated}}</span>
                      </div>
                    </div>
                  </mat-option>
                }
              }
            </mat-autocomplete>

            <input
              matInput
              [formControl]="iptcControl.innerControl"
              [placeholder]="getMultiControl(formControlName).placeholder"
              [matAutocomplete]="autocomplete"
              >

            <div matSuffix>
              <ng-container *ngTemplateOutlet="resetToGeneratedButton; context: {iptcControl}"></ng-container>
              <ng-container *ngTemplateOutlet="resetButton; context: {formControlName}"></ng-container>
            </div>
          </mat-form-field>
        </insc-iptc-form-field-wrapper>
      </insc-multi-field>
    </ng-template>




    <!-- FORM FIELD ROWS -->
    <div class="form-row">
      <div class="label">Titel</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'title'}"></ng-container>
    </div>

    <div class="form-row">
      <div class="label">Überschrift</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'headline'}"></ng-container>
    </div>

    <div class="form-row">
      <div class="label">Beschreibung</div>
      <insc-multi-field
        [editable]="editable"
        [multiRecordFormControl]="getMultiControl('description')"
        [getDisplayValue]="getIptcTextFieldDisplayValue"
        >
        <insc-iptc-form-field-wrapper #iptcControlDescription formControlName="description">
          <mat-form-field [ngClass]="getFieldClass(iptcControlDescription.state)">

            <div matPrefix>
              <ng-container *ngTemplateOutlet="iptcFieldStateIcon; context: {iptcControl: iptcControlDescription}"></ng-container>
            </div>

            <textarea matInput cdkTextareaAutosize
              [formControl]="iptcControlDescription.innerControl"
              [placeholder]="getMultiControl('description').placeholder"
            ></textarea>

            <div matSuffix>
              <ng-container *ngTemplateOutlet="resetToGeneratedButton; context: {iptcControl: iptcControlDescription}"></ng-container>
              <ng-container *ngTemplateOutlet="resetButton; context: {formControlName: 'description'}"></ng-container>
            </div>
          </mat-form-field>
        </insc-iptc-form-field-wrapper>
      </insc-multi-field>
    </div>

    <div class="form-row">
      <div class="label">Ersteller</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'creator'}"></ng-container>
    </div>

    <div class="form-row">
      <div class="label">Verfasser der Beschreibung</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'description_writer'}"></ng-container>
    </div>

    <div class="form-row">
      <div class="label">Quelle</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'source'}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Copyright-Vermerk</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'copyright_notice'}"></ng-container>
    </div>

    <div class="form-row">
      <div class="label">Copyright-Status</div>
      <insc-multi-field
        [editable]="editable"
        [multiRecordFormControl]="getMultiControl('marked')"
        [getDisplayValue]="getCopyrightStatusDisplayValue"
        >
        <insc-iptc-form-field-wrapper #iptcControlMarked formControlName="marked">

          <mat-form-field [ngClass]="getFieldClass(iptcControlMarked.state)">

            <div matPrefix>
              <ng-container *ngTemplateOutlet="iptcFieldStateIcon; context: {iptcControl: iptcControlMarked}"></ng-container>
            </div>

            <mat-select [formControl]="iptcControlMarked.innerControl">
              <mat-option [value]="true">{{ iptcDatasetService.getCopyrightStatusString(true) }}</mat-option>
              <mat-option [value]="false">{{ iptcDatasetService.getCopyrightStatusString(false) }}</mat-option>
            </mat-select>

            <div matSuffix>
              <ng-container *ngTemplateOutlet="resetToGeneratedButton; context: {iptcControl: iptcControlMarked}"></ng-container>
              <ng-container *ngTemplateOutlet="resetButton; context: {formControlName: 'marked'}"></ng-container>
            </div>
          </mat-form-field>
        </insc-iptc-form-field-wrapper>
      </insc-multi-field>
    </div>

    <div class="form-row">
      <div class="label">Credit (Anbieter)</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'credit_line'}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Nutzungsbedingungen</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'rights_usage_terms'}"></ng-container>
    </div>

    <div class="form-row">
      <div class="label">Ort</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'city'}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Ortsdetail</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'sublocation'}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Bundesland/Kanton</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'province_or_state'}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">Land</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'country'}"></ng-container>
    </div>
    <div class="form-row">
      <div class="label">ISO-Landescode</div>
      <ng-container *ngTemplateOutlet="textField; context: {formControlName: 'country_code'}"></ng-container>
    </div>


    <!-- KEYWORDS -->
    @if (getMultiArray('keywords'); as multiArray) {
      <div class="form-row">
        <div class="label">
          <div>Schlagwörter</div>
          @if (multiArray.hasCommonItems) {
            <div>(gemeinsame Werte)</div>
          }
        </div>
        <div class="keywords-container">
          @if (editable && hasKeywordOverrides(); as hasKeywordOverrides) {
            <div class="form-group-title"
              [ngClass]="{'manual-value': hasKeywordOverrides !== 'none'}"
              >
              @if (hasKeywordOverrides === 'none') {
                <mat-icon class="compact-prefix">auto_fix_normal</mat-icon>
                <span>Automatisch generierte Werte</span>
              } @else {
                <mat-icon class="compact-prefix">🅼</mat-icon>
                @if (hasKeywordOverrides === 'all') {
                  <span> Manuell festgelegte Werte</span>
                }
                @if (hasKeywordOverrides === 'some') {
                  <span> Teils manuell festgelegte Werte</span>
                }
              }
              <div class="form-group-title-buttons">
                <button mat-icon-button
                  matTooltip="Schlagwort hinzufügen"
                  (click)="addNewKeyword(multiArray)"><mat-icon>add</mat-icon></button>
                  @if (hasKeywordOverrides !== 'none') {
                    <button mat-icon-button
                      (click)="resetKeywordsToGenerated()"
                      matTooltip="Alle auf generierte  Werte setzen">
                      <mat-icon>auto_fix_high</mat-icon>
                    </button>
                  }
                  @if (multiArray.formArray.dirty) {
                    <button mat-icon-button
                      (click)="restoreManuallySetKeywords()"
                      matTooltip="Änderungen auf Ursprungswerte zurücksetzen">
                      <mat-icon>undo</mat-icon>
                    </button>
                  }
                </div>
              </div>
            }
            <insc-multi-group
              [editable]="editable"
              [multiRecordFormArray]="multiArray"
              class="keywords"
              >
              <div *inscMultiGroupInnerControl="let formControl; let index = index; let isLast = last"  class="entry-row">
                @if (getKeywordState(multiArray, index); as keywordState) {
                  <insc-iptc-form-field-wrapper [formControl]="formControl" #iptcControl>
                    <mat-form-field>
                      <!-- PREFIX ICONS -->
                      <!-- image selection inscludes generated keywords -->
                      @if (displayKeywordUntouchedGeneratedIcon(keywordState)) {
                        <mat-icon
                          matIconPrefix class="compact-prefix"
                          [matTooltip]="getKeywordPrefixIconTooltip('untouchedGeneratedIcon', keywordState.hasUntouchedGeneratedKeywords)"
                          fontSet="material-icons-outlined"
                        >auto_fix_normal</mat-icon>
                      }
                      <!-- image selection inscludes removed generated keywords -->
                      @if (displayKeywordRemovedGeneratedIcon(keywordState)) {
                        <mat-icon
                          matIconPrefix class="compact-prefix" color="warn"
                          [matTooltip]="getKeywordPrefixIconTooltip('removedGeneratedIcon', keywordState.hasRemovedGeneratedKeywords)"
                        >auto_fix_off</mat-icon>
                      }
                      <!-- image selection includes manual keywords -->
                      @if (displayKeywordManualIcon(keywordState)) {
                        <mat-icon
                          matIconPrefix class="compact-prefix manual"
                          [matTooltip]="getKeywordPrefixIconTooltip('manualIcon', keywordState.hasManualKeywords)"
                        >🅼</mat-icon>
                      }
                      <!-- manual keyword that has been marked for deletion -->
                      @if (displayKeywordRemovedManualIcon(keywordState)) {
                        <mat-icon
                          matIconPrefix class="compact-prefix" color="warn"
                          [matTooltip]="getKeywordPrefixIconTooltip('removedGeneratedIcon', keywordState.hasManualKeywords)"
                        >delete</mat-icon>
                      }
                      <!-- is newly added keyword -->
                      @if (keywordState.isNew) {
                        <mat-icon
                          matIconPrefix class="compact-prefix"
                          [matTooltip]="images.length | i18nPlural: keywordButtonTooltipStrings.newlyAddedIcon"
                        >auto_awesome</mat-icon>
                      }
                      <!-- Form field -->
                      @if (!keywordState.isNew) {
                        <input matInput [formControl]="iptcControl.innerControl">
                      }
                      @if (keywordState.isNew) {
                        <input #newKeywordInput matInput [formControl]="iptcControl.innerControl">
                      }
                      <!-- Delete / restore buttons -->
                      @if (displayKeywordDeleteButton(keywordState)) {
                        <button
                          mat-icon-button
                          class="compact-suffix-button" matSuffix
                          [matTooltip]="images.length | i18nPlural: keywordButtonTooltipStrings.deleteButton"
                          (click)="markKeywordForDeletion(multiArray, index)">
                          <mat-icon>delete</mat-icon></button>
                        }
                        @if (displayKeywordRestoreButton(keywordState)) {
                          <button
                            mat-icon-button
                            class="compact-suffix-button" matSuffix
                            [matTooltip]="images.length | i18nPlural: keywordButtonTooltipStrings.restoreButton"
                            (click)="markKeywordForRestauration(multiArray, index)">
                            <mat-icon>restore_from_trash</mat-icon></button>
                          }
                          <!-- Reset to original button -->
                          @if (formControl.dirty && !keywordState.isNew) {
                            <button matSuffix mat-icon-button
                              [matTooltip]="images.length | i18nPlural: keywordButtonTooltipStrings.resetToOriginalButton"
                              (click)="resetKeyword(multiArray, index)">
                              <mat-icon>undo</mat-icon>
                            </button>
                          }
                        </mat-form-field>
                      </insc-iptc-form-field-wrapper>
                    }
                  </div>
                  <!-- readonly view -->
                  <div *inscMultiGroupReadonlyView="let formValue; let index = index;">
                    @if (findRemovedGeneratedKeywords(multiArray,  index) === 'none') {
                      <div class="array-item">{{ getIptcTextFieldDisplayValue(formValue) }}</div>
                    }
                  </div>
                </insc-multi-group>
                @if (editable && multiArray.commonValueState !== 'hasCommonValues') {
                  <button class="add-button-for-empty-array"  mat-icon-button matSuffix matTooltip="Eintrag hinzufügen" (click)="multiArray.addNewCommonItem()"><mat-icon>add</mat-icon></button>
                }
              </div>
            </div>
          }

        </div>
