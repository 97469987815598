<ng-container
  inscSearchController
  #searchController="inscSearchController"
  (queryParamsChange)="onQueryParamsChange($event)"
  >
  @if ({
    results: searchResults$ | async,
    resultImages: resultImages$ | async,
    currentCriteria: searchController.currentCriteria$ | async
    }; as observables) {
    <h1 class="overview-title">
      @if (observables.currentCriteria?.length > 0) {
        Gefundene
      } @else {
        <span>Alle </span>
      }
      <ng-template #all><span>Alle </span></ng-template>
      Aufnahmen
      @if (observables.results; as results) {
        <span>
          ({{results?.count}})
        </span>
      }
    </h1>
    <div class="container">
      <!-- FACET SIDEBAR -->
      <div class="facet-sidebar">
        <mat-card class="facet-content">
          <h2 matCardTitle>
            Filterkriterien
          </h2>
          <insc-dating-search-control
            inscSearchControl="dating"
            searchControlDisplayName="Datierung"
            [resultParams]="observables.results?.dating"
          ></insc-dating-search-control>
          <!--          <insc-fulltext-search-control-->
          <!--            inscSearchControl="fulltext"-->
          <!--            searchControlDisplayName="Volltext"-->
          <!--            [resultParams]="observables.results?.fulltext"-->
        <!--          ></insc-fulltext-search-control>-->
        <mat-expansion-panel class="recording-year-panel" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>Aufnahmejahr</mat-panel-title>
          </mat-expansion-panel-header>
          <insc-recording-date-search-control
            inscSearchControl="recording_date"
            searchControlDisplayName="Aufnahmejahr"
            [resultParams]="observables.results?.recording_date"
          ></insc-recording-date-search-control>
        </mat-expansion-panel>
        <insc-facet-list-search-control
          inscSearchControl="filters"
          [facetResults]="observables.results?.facets"
          [resultParams]="observables.results?.filters"
          >
          <insc-facet title="Aufnahmezeitraum" field="recording_date_decades"></insc-facet>
          <insc-facet title="Standort des Inschriftenträgers" field="locations.search_path"></insc-facet>
          <insc-facet title="Aufnahmekategorie" field="recording_category"></insc-facet>
          <insc-facet title="Fotograf-/in" field="photographer_name"></insc-facet>
          <insc-facet title="Rechteinhaber-/in" field="copyright_holder.name"></insc-facet>
          <insc-facet title="Lizenz" field="license.name"></insc-facet>
          <insc-facet title="Verwendungsnachweis" field="literature_references.literature_entry.name"></insc-facet>
          <insc-facet title="Bearbeitungsstatus" field="editing_status_string"></insc-facet>
          <insc-facet title="Administratives" field="admin_data"></insc-facet>
        </insc-facet-list-search-control>
      </mat-card>
    </div>
    <div class="content-wrap">
      <!-- SEARCH RESULTS -->
      <insc-search-toolbar>
        <insc-sort-control [compact]="true" [sortBy]="observables.results?.sort_by"></insc-sort-control>
        @if (observables.resultImages) {
          <div class="image-controls">
            <mat-divider [vertical]="true"></mat-divider>
            <button [disabled]="allSelectedOf(observables.resultImages)" mat-icon-button matTooltip="Alle auf dieser Seite sichtbaren Ergebnisse zur Auswahl hinzufügen" (click)="imageManagementGroup.selectAll()">
              <mat-icon>done_all</mat-icon>
            </button>
            <button [disabled]="noneSelectedOf(observables.resultImages)" mat-icon-button matTooltip="Alle auf dieser Seite sichtbaren ausgewählten Ergebnisse abwählen" (click)="imageManagementGroup.deselectAll()">
              <mat-icon>remove_done</mat-icon>
            </button>
          </div>
        }
          <insc-paginator [pageParams]="{
          page: observables.results?.page,
          pageSize: observables.results?.page_size,
          count: observables.results?.count
        }"></insc-paginator>
      </insc-search-toolbar>
      <insc-search-criteria-chips
        [currentCriteria]="observables.currentCriteria"
        (removeCriterium)="searchController.removeCriterium($event)"
        (resetCriteria)="searchController.resetCriteria()"
      ></insc-search-criteria-chips>
      @if (observables.resultImages) {
        <insc-image-management-group
          [imageGroupDescriptor]="{type: 'results'}"
          [images]="observables.resultImages"
          [inscImageManagement]
          (imageSelectionChange)="addSelection($event, observables.resultImages)"
          >
          <div class="results-wrapper">
            @for (result of observables.results.results; track result) {
              <insc-image-result-card
              [result]="result['data']" [highlight]="result['highlight']" [imageMenu]="imageMenu"></insc-image-result-card>
            }
          </div>
        </insc-image-management-group>
      }
      @if (selectedImages?.length > 0) {
        <div class="selection-list">
          <div class="header">
            <span>{{selectedImages.length}} ausgewählt</span>
            <button mat-icon-button class="deselect-button" color="primary" (click)="clearSelections()" matTooltip="Gesamte auswahl aufheben">
              <mat-icon>cancel</mat-icon>
            </button>
          </div>
          <div class="list">
            @for (image of selectedImages; track image) {
              <insc-image-tile [inline]="true" [disableSelect]="true" [image]="image" [matTooltip]="image.name"></insc-image-tile>
            }
          </div>
        </div>
      }
    </div>
  </div>
}

</ng-container>


<!-- Templates for the image menu triggered by the hover button -->
<mat-menu #imageMenu="matMenu">
  <ng-template matMenuContent let-image>

    <div class="insc-menu-header image-menu-header">
      <b>Verknüpfte Datensätze öffnen</b>
      <mat-divider></mat-divider>
    </div>

    <div class="insc-menu-header">Inschriftenträger</div>
    @for (inscObject of getAllUniqueRelatedInscObjects(image); track inscObject) {
      @if (getInscriptionsForInscObject(image, inscObject); as inscriptions) {
        @if (inscriptions.length > 0) {
          <a mat-menu-item class="insc-narrow-menu-item link-menu-item"
            href="/objects/{{inscObject.id}}" target="_blank"
            [matMenuTriggerFor]="inscriptionSubMenu"
            [matMenuTriggerData]="{inscObject, inscriptions}"
            title="{{inscObject.name}}"
          >{{inscObject.name}}</a>
        } @else {
          <a mat-menu-item class="insc-narrow-menu-item link-menu-item"
            href="/objects/{{inscObject.id}}" target="_blank"
            title="{{inscObject.name}}"
          >{{inscObject.name}}</a>
        }
      }
    }


    <div class="insc-menu-header">
      <mat-divider></mat-divider>
      <em>ℹ Links öffnen sich in neuem Tab</em>
    </div>
  </ng-template>
</mat-menu>

<mat-menu #inscriptionSubMenu="matMenu" yPosition="above">
  <ng-template matMenuContent let-inscObject="inscObject" let-inscriptions="inscriptions">
    <div class="insc-menu-header">Inschriften</div>
    @for (inscription of inscriptions; track inscription) {
      <a mat-menu-item class="insc-narrow-menu-item link-menu-item"
        href="/objects/{{inscObject.id}}/inscriptions/{{inscription.id}}" target="_blank"
        title="{{inscription.name}}"
      >{{inscription.name}}</a>
    }
  </ng-template>
</mat-menu>
