<ng-container
  inscSearchController
  #searchController="inscSearchController"
  (queryParamsChange)="onQueryParamsChange($event)">

  @if ({
    results: dataSource.searchResults$ | async,
    currentCriteria: searchController.currentCriteria$ | async
    }; as observables) {
    <div class="toolbar">
      <insc-fulltext-search-control class="control"
        inscSearchControl="name_quick_filter"
        searchControlDisplayName="Volltext"
        [emitOnType]="true"
        apperance="standard"
        title="Filter"
      ></insc-fulltext-search-control>
      <mat-divider vertical></mat-divider>
      <insc-sort-control [sortBy]="observables.results?.sort_by" [disabled]="disabled" class="control"></insc-sort-control>
    </div>
    <mat-divider class="dotted"></mat-divider>
    <!-- mat-selection-list with integrated SelectionModel does not work reliably with virtual scroll, looses selections
    even with custom compareWith function or trackBy function on the virtual scroll viewpport
    -->
    <cdk-virtual-scroll-viewport itemSize="48" class="list-viewport insc-virtual-scroll-width-100p">
      <mat-selection-list (selectionChange)="onSelectionListSelectionChange($event)" [multiple]="multiple">
        <ng-template #option let-record>
          @if (record) {
            <mat-list-option [value]="record" [selected]="isSelected(record)">
              <span matListItemTitle>
                <img class="thumb" [src]="record?.preview_url">
                <span [innerHTML]="record?.name | highlight: observables.results?.name_quick_filter"></span>
              </span>
            </mat-list-option>
          } @else {
            <mat-list-item>
              <mat-spinner diameter="25"></mat-spinner>
            </mat-list-item>
          }
        </ng-template>
        <ng-container *cdkVirtualFor="let record of dataSource; templateCacheSize: 0; template: option"></ng-container>
      </mat-selection-list>
    </cdk-virtual-scroll-viewport>
  }
</ng-container>

