import { ChangeDetectionStrategy, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, ViewChild } from "@angular/core"
import { TileSource, Viewer } from "openseadragon"
import OpenSeadragon from "openseadragon"

import { InscImage } from "../../models/image.model"

@Component({
  selector:        'insc-image-viewer',
  templateUrl:     './image-viewer.component.html',
  styleUrls:       ['./image-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageViewerComponent implements OnChanges, OnDestroy {

  @Input() images: InscImage[] = []
  @Input() showImageTitle = true
  @Input() fallbackImageMode: "preview" | "display" | "pyramid" = "display"
  @Input() collectionMode = false
  @Input() enableRotation = true
  @Input() showReferenceStrip = false

  title: string

  private viewer: Viewer

  @ViewChild('viewerElem') viewerElem: ElementRef

  constructor(private ngZone: NgZone) {}

  ngOnChanges() {
    this.viewer && this.viewer.destroy()

    if (this.images == null || this.images.length === 0) {
      return
    }

    let tileSources: (string | Partial<TileSource>)[] = this.images.map(image => {
      if (image.iiif_url) {
        return `${image.iiif_url}/info.json`
      } else if (this.fallbackImageMode === "pyramid" && image.dimensions && image.preview_dimensions) {
        return {
          type: "legacy-image-pyramid",
          levels: [{
            url: image.preview_url,
            width: image?.preview_dimensions[0],
            height: image?.preview_dimensions[1]
          },
                   {
                     url: image.display_url,
                     width: image?.dimensions[0],
                     height: image?.dimensions[1]
                   }]
        }
      } else {
        return {
          type: "image",
          url: this.fallbackImageMode === "display" ? image.display_url : image.preview_url
        }
      }
    })


    const titles = this.images.map( image => image.name )
    const modeOptions = this.collectionMode ? { collectionMode: true } : { sequenceMode: true }


    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => {
        this.viewer = OpenSeadragon({
          element:            this.viewerElem.nativeElement,
          prefixUrl:          "/assets/openseadragon/images/",
          tileSources:        tileSources as (string | OpenSeadragon.TileSource[]),
          showRotationControl: this.enableRotation,
          // Enable touch rotation on tactile devices
          gestureSettingsTouch: {
            // @ts-ignore
            pinchRotate: this.enableRotation
          },
          showReferenceStrip: this.showReferenceStrip,
          ...modeOptions
        })

        if (this.showImageTitle) {
          this.title = titles[0]
          this.viewer.addHandler("page", (page) => this.title = titles[page.page])
        }
      })
    })
  }

  ngOnDestroy() {
    this.viewer.destroy()
  }

}
