import { ChangeDetectorRef, Component, Inject, Injectable, Injector, OnInit, ViewChild } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog"
import { BehaviorSubject, Observable, of } from "rxjs"
import { catchError, switchMap, tap } from "rxjs/operators"
import { DataService, QueryParams, QueryResults } from "../../../../../services/data.service"
import {
  ExtraQueryParams,
  RecordPickerComponent,
  RecordPickerSelection
} from "../../../../../shared/components/record-picker/record-picker.component"
import { InscEntity } from "../../../../../shared/models/entity.model"

export type GetConfigFromSelectionFunc<T extends InscEntity> = (selection: RecordPickerSelection<T>[]) => LinkChooserDialogConfig<T> | Observable<LinkChooserDialogConfig<T>>

export interface LinkChooserDialogConfig<T extends InscEntity> {
  queryParams?: ExtraQueryParams
  title?: string
  canSave?: boolean
  infoMessage?: string
  validationMessage?: string
}
interface AbstractLinkChooserDialogData<T extends InscEntity> {
  dataService: typeof DataService<T>
  select?: RecordPickerSelection<T>[]
}
export interface LinkChooserDialogDataStaticConfig<T extends InscEntity> extends AbstractLinkChooserDialogData<T> {
  config: LinkChooserDialogConfig<T>
  getConfigFromSelection?: never
}
export interface LinkChooserDialogDataDynamicConfig<T extends InscEntity> extends AbstractLinkChooserDialogData<T> {
  config?: never
  getConfigFromSelection: GetConfigFromSelectionFunc<T>
}
type LinkChooserDialogData<T extends InscEntity> = LinkChooserDialogDataStaticConfig<T> | LinkChooserDialogDataDynamicConfig<T>

export interface LinkChooserDialogResult<T extends InscEntity> {
  selection: RecordPickerSelection<T>[]
}

export type LinkChooserDialogRef<T extends InscEntity> = MatDialogRef<
  LinkChooserDialogComponent<T>,
  LinkChooserDialogResult<T>
>

@Injectable({providedIn: 'root'})
export class LinkChooserDialogService {
  constructor(private dialog: MatDialog) {}

  open<T extends InscEntity>(data: LinkChooserDialogData<T>): LinkChooserDialogRef<T> {
    return this.dialog.open<
    LinkChooserDialogComponent<T>,
    LinkChooserDialogData<T>,
    LinkChooserDialogResult<T>
    >(LinkChooserDialogComponent, {
      width: "600px",
      height: "80vh",
      data
    })
  }
}

@Component({
  selector:    'insc-link-chooser-dialog',
  templateUrl: './link-chooser-dialog.component.html',
  styleUrls:   ['./link-chooser-dialog.component.scss'],
})
export class LinkChooserDialogComponent<T extends InscEntity> implements OnInit {
  set loading(value: boolean) {
    this._loading = value
    this.cdr.detectChanges()
  }

  dataService = this.injector.get(this.data.dataService)
  config: BehaviorSubject<LinkChooserDialogConfig<T>>
  private _loading: boolean = false

  @ViewChild(RecordPickerComponent) recordPicker: RecordPickerComponent<T>

  constructor(
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) readonly data: LinkChooserDialogData<T>,
    @Inject(MatDialogRef) private dialogRef: LinkChooserDialogRef<T>
  ) { }


  recordPickerGetSearchResults = (queryParams: QueryParams): Observable<QueryResults<T>> => this.config.pipe(
    tap(() => this._loading = true),
    switchMap(config => this.dataService.all({...queryParams, ...(config.queryParams ?? {})}).pipe(
      catchError(() => {
        this._loading = false
        return of(null)
      })
    )),
    tap(() => this._loading = false),
  )

  onSelectionChange(selection: RecordPickerSelection<T>[], _picker: RecordPickerComponent<T>): void {
    if (this.data.getConfigFromSelection) {
      this._loading = true
      this.getObservableConfig(this.data.getConfigFromSelection(selection)).subscribe({
        next: config => this.config.next(config),
        error: () => this._loading = false
      })
    }
  }

  ngOnInit(): void {
    if (!this.data) {
      throw new Error("Dialog data must be set.")
    }

    if (this.data.getConfigFromSelection) {
      this.getObservableConfig(this.data.getConfigFromSelection(this.data.select)).subscribe(
        initialConfig => this.config = new BehaviorSubject(initialConfig)
      )
    } else {
      this.config = new BehaviorSubject(this.data.config)
    }
  }

  save() {
    this.dialogRef.close({selection: this.recordPicker.selectedRecords})
  }

  private getObservableConfig(observableOrValue: LinkChooserDialogConfig<T> | Observable<LinkChooserDialogConfig<T>>) {
    return observableOrValue instanceof Observable ? observableOrValue : of(observableOrValue)
  }

}
