/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// https://medium.com/@rachelheimbach/rxjs-state-management-in-angular-2-error-handling-678deabf3331

import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpRequest, HttpResponse } from "@angular/common/http"
import { Injectable } from "@angular/core"

import { combineLatest, Observable, of, Subject } from "rxjs"
import { distinctUntilChanged, filter, map, pluck, startWith, switchMap, tap } from "rxjs/operators"
import { environment } from "../../environments/environment"

import { DateRangeModifier, InscEntity, InscImage, InscObject, Inscription, ObjectGroup, ObjectLocation } from "../shared/models"
import { EditingStatus } from "../shared/models/entity.model"
import { ImageParent } from "../shared/models/image.model"
import { ImageUploadEventType, ImageUploadProgressEvent } from "./image-file.service"
import { QueryStringService } from "./query-string.service"

export type EntityType = "insc_object" | "inscription" | "image" | "images" | "location" | "object_group" | "user"
export type APIResult<T> = {[type in EntityType]: T}


export interface QueryDatingValue {
  primary?: string
  secondary?: string
  range_modifier?: DateRangeModifier
  intersects?: string
}
export interface QueryDatingParams {
  dating?: QueryDatingValue
}

export interface QueryFulltextParams {
  fulltext?: string
}

export interface QueryPageParams {
  page?: number
  page_size?: number
}

export interface QuerySortParams {
  sort_by?: 'dating_asc' | 'dating_desc' | 'name' | 'updated_at' | 'created_at'
}

export interface FilterValues {
  [name: string]: string[]
}
export interface QueryFilterParams {
  filters?: FilterValues
}

export interface QueryRecordingDateParams {
  recording_date?: {
    year_latest?: number
    year_earliest?: number
    exact?: boolean
  }
}

export type QueryParams = QueryDatingParams & QueryFulltextParams & QueryPageParams & QueryFilterParams & QuerySortParams & QueryRecordingDateParams

export interface FacetResult {
  key: string
  doc_count: number
  selected: boolean
}

export interface FacetResults {
  [name: string]: FacetResult[]
}

export interface QueryResult<T> {
  id: string
  name: string
  preview_url: string
  data: T
  highlight: Partial<T>
  type: string
}

export interface QueryResults<T> extends QueryParams {
  count: number
  results: QueryResult<T>[]
  dating?: QueryDatingValue & {
    description: string
  }
  facets: FacetResults
}


@Injectable()
export abstract class DataService<T extends InscEntity> {

  baseUrl = environment.apiUrl
  readonly abstract dataType: EntityType

  private _updates = new Subject<T[]>()

  protected constructor(protected http: HttpClient, protected queryString: QueryStringService) { }

  // workaround; waiting for https://github.com/angular/angular/issues/23856
  toHttpParams = (params: {[key: string]: string | number | boolean}) => {
    return Object.keys(params).reduce(
      (currentHttpParams, key) => currentHttpParams.set(key, String(params[key])),
      new HttpParams()
    )
  }

  protected notifyUpdates(updates: T[]) {
    this._updates.next(updates)
  }

  all(params: QueryParams | Observable<QueryParams> = {}): Observable<QueryResults<T>> {
    const queryParams$ = params instanceof Observable ? params : of(params)
    const queryString$ = queryParams$.pipe(
      map(queryParams => this.queryString.paramsToQueryString(queryParams))
    )
    const updates$ = this._updates.pipe(startWith([]))

    return combineLatest([queryString$, updates$]).pipe(
      switchMap(([queryString, _]) => this.http
        .get<QueryResults<T>>(`${this.baseUrl}/${this.dataType}s?${queryString}`)
      )
    )
  }

  get(id: string, params: {[key: string]: string | number | boolean} = {}) {
    return this.http.get<APIResult<T>>(`${this.baseUrl}/${this.dataType}s/${id}`, {params: this.toHttpParams(params)}).pipe(pluck(this.dataType))
  }

  save(record: Partial<T>, endpointUrl?: string) {
    const method = record.id ? "PUT" : "POST"
    const postData = {[this.dataType]: record}
    const url = endpointUrl ?? `${this.dataType}s/${record.id || ""}`
    const req = new HttpRequest(method, `${this.baseUrl}/${url}`, postData, {responseType: "json"})

    return this.http.request<{[type in EntityType]: T}>(req).pipe(
      filter(response => response.type === HttpEventType.Response),
      map((response: HttpResponse<APIResult<T>>) => response.body[this.dataType]),
      tap(response => this.notifyUpdates([response]))
    )
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${this.dataType}s/${id}`).pipe(
      tap(() => this.notifyUpdates([]))
    )
  }

  clone(id: string) {
    return this.http.get<APIResult<T>>(`${this.baseUrl}/${this.dataType}s/${id}/clone`).pipe(pluck(this.dataType))
  }

  setEditingStatus(id: string, status: EditingStatus) {
    return this.http.put<APIResult<T>>(`${this.baseUrl}/${this.dataType}s/${id}/editing_status/${status}`, {})
      .pipe(pluck(this.dataType))
  }
}


@Injectable({providedIn: 'root'})
export class ObjectLocationService extends DataService<ObjectLocation> {

  readonly dataType: EntityType = "location"

  constructor(http: HttpClient, protected queryString: QueryStringService) {
    super(http, queryString)
  }

  locations(parent_id?: string, expand_ids?: string | string[], find_id?: string, query?: string) {

    const params = {}

    if (parent_id) { params["parent_id"] = parent_id }
    if (expand_ids) { params["expand_ids"] = [].concat(expand_ids).join(",") }
    if (find_id) { params["find_id"] = find_id }
    if (query) { params["query"] = query }

    return this.http.get<{locations: Partial<ObjectLocation>[]}>(`${this.baseUrl}/locations`, {params: params}).pipe(
      map(response => response.locations)
    )
  }

  query(queryString: string) {
    return queryString && queryString.length > 0
      ? this.http.get<{locations: Partial<ObjectLocation>[]}>(`${this.baseUrl}/locations?query=${queryString}`)
        .pipe(map(result => result.locations))
      : of(null)
  }
}

@Injectable({providedIn: 'root'})
export class InscObjectService extends DataService<InscObject> {
  readonly dataType: EntityType = "insc_object"
}

@Injectable({providedIn: 'root'})
export class ObjectGroupService extends DataService<ObjectGroup> {
  readonly dataType: EntityType = "object_group"

  // TODO: override all?
  list(): Observable<ObjectGroup[]> {
    return this.http.get<{object_groups: ObjectGroup[]}>(`${this.baseUrl}/${this.dataType}s`).pipe(
      map(result => result.object_groups)
    )
  }

  saveForInscObject(objectGroup: Partial<ObjectGroup>, inscObjectId: string) {
    return this.http.post<APIResult<ObjectGroup>>(`${this.baseUrl}/insc_objects/${inscObjectId}/${this.dataType}/`, {object_group: objectGroup})
      .pipe(pluck("object_group"))
  }
}


@Injectable({providedIn: 'root'})
export class InscriptionService extends DataService<Inscription> {
  readonly dataType: EntityType = "inscription"

  saveForObject(inscription: Partial<Inscription>, objectId: string | null) {
    const isNewRecord = !inscription.id
    return isNewRecord ? this.save(inscription, `insc_objects/${objectId}/inscriptions/`) : this.save(inscription)
  }
}

export type ImageMetadataValue = Array<string | number> | number | string | boolean
export type ImageMetadataRecord = Record<string, ImageMetadataValue>
@Injectable({providedIn: "root"})
export class ImageService extends DataService<InscImage> {
  readonly dataType = "image"

  metadata(imageId: InscImage["id"]) {
    return this.http.get<ImageMetadataRecord>(`${this.baseUrl}/images/${imageId}/metadata`)
  }

  batchUpdate(updates: Partial<InscImage>[]) {
    return this.http.put<APIResult<InscImage[]>>(`${this.baseUrl}/images/_batch`, {images: updates}).pipe(
      map(result => result.images),
      tap(result => this.notifyUpdates(result))
    )
  }

  saveForParent(
    image: Partial<InscImage>,
    parent: ImageParent
  ) {

    if (!parent) {
      return this.save(image)
    }

    const postData = {[this.dataType]: image}

    switch (parent.type) {
      case "inscription":
        return this.http.post<APIResult<InscImage>>(`${this.baseUrl}/inscriptions/${parent.id}/images/${image.id || ""}`, postData).pipe(
          pluck(this.dataType)
        )
      case "insc_object":
        return this.http.post<APIResult<InscImage>>(`${this.baseUrl}/insc_objects/${parent.id}/${parent.isContext ? "context_images" : "images"}/${image.id || ""}`, postData).pipe(
          pluck(this.dataType)
        )
    }
  }

  saveWithFile(image: Partial<InscImage>, file: File, parent?: ImageParent) {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("image", new Blob([JSON.stringify(image)], {type: "application/json"}))

    let url: string
    if (!parent) {
      url = `${this.baseUrl}/images`
    } else if (parent.type === "inscription") {
      url = `${this.baseUrl}/inscriptions/${parent.id}/images`
    } else if (parent.type === "insc_object") {
      url = `${this.baseUrl}/insc_objects/${parent.id}/${parent.isContext ? "context_images" : "images"}`
    }

    const req = new HttpRequest("POST", url, formData, {reportProgress: true})
    return this.http.request<APIResult<InscImage>>(req).pipe(
      map<HttpEvent<APIResult<InscImage>>, ImageUploadProgressEvent>(event => {
        if (event.type === HttpEventType.UploadProgress) {
          return {
            type:   ImageUploadEventType.Progress,
            total:  event.total,
            loaded: event.loaded
          }
        } else if (event.type === HttpEventType.ResponseHeader) {
          return {
            type: ImageUploadEventType.Uploaded
          }
        } else if (event.type === HttpEventType.Response) {
          return {
            type: ImageUploadEventType.Finished,
            updatedImageRecord: event.body.image
          }
        }
      }),
      filter(event => event != null),
      distinctUntilChanged()
    )
  }
}

