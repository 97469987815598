<insc-back-to-results-link></insc-back-to-results-link>

<!-- HEADER -->
<div class="header">
  <h3>Inschrift</h3>
  <h1 class="name">{{inscription.name}}</h1>
  @for (location of distinctLocations(inscription.locations); track location.id) {
    <insc-location-header-view  [location]="location"></insc-location-header-view>
  }
</div>

<!-- CONTENT -->
<div class="columns">

  <!-- IMAGE COLUMN -->
  <div
    [inscImageManagement]="'inscription_' + inscription.id"
    [canChangeSelection]="canChangeImageSelection"
    (imageSelectionChange)="onImageSelectionChange($event)"
    #imageManagement="imageManagement"
    class="image-column"
    >

    <insc-record-image-list-container
      [recordName]="inscription.name"
      [imageParent]="{type: 'inscription', id: inscription.id}"
      [showDownloadButton]="true"
      (linkImageClicked)="linkImages()"
      (unlinkClicked)="unlinkImages($event)"
      (uploadImagesClicked)="openImageUploader()"
      >
      <ng-template inscRecordImageListTitle>
        <div class="section-title">Aufnahmen der Inschrift</div>
      </ng-template>

      <insc-image-management-group
        [imageGroupDescriptor]="{type: 'inscription_images', id: inscription.id}"
        [images]="inscription.images"
        (imageDrop)="onImageDrop($event)"
        [ngClass]="{dragging: imageManagement.dragging$ | async}"
        >
        @for (image of inscription.images; track image) {
          <insc-image-tile inscRecordImageListImage [image]="image"></insc-image-tile>
        }
      </insc-image-management-group>

    </insc-record-image-list-container>

  </div>

  <!-- DATA COLUMN -->
  <div class="metadata-column">

    <div class="section">
      <div class="section-title">Allgemein</div>
      <insc-data-group>
        <div inscDataField>
          <div *inscFieldLabel>Anbringungsort am Inschriften&shy;träger</div>
          <div *inscFieldValue>{{inscription.location_on_object}}</div>
        </div>
        <div inscDataField [editorialState]="inscription.inscription_types_editorial_state">
          <div *inscFieldLabel>Texttyp</div>
          @for (value of inscription.inscription_types; track value) {
            <div>
              <span *inscFieldValue>{{ value }}</span>
            </div>
          }
        </div>
        <div inscDataField [editorialState]="inscription.inscription_materials_editorial_state">
          <div *inscFieldLabel>Material</div>
          @for (value of inscription.inscription_materials; track value) {
            <div>
              <span *inscFieldValue>{{ value }}</span>
            </div>
          }
        </div>
        <div inscDataField [editorialState]="inscription.inscription_techniques_editorial_state">
          <div *inscFieldLabel>Technik</div>
          @for (value of inscription.inscription_techniques; track value) {
            <div>
              <span *inscFieldValue>{{ value }}</span>
            </div>
          }
        </div>
        <div inscDataField [editorialState]="inscription.inscription_condition_editorial_state">
          <div *inscFieldLabel>Zustand</div>
          <div *inscFieldValue>{{inscription.inscription_condition}}</div>
        </div>
        <div inscDataField [editorialState]="inscription.typefaces_editorial_state">
          <div *inscFieldLabel>Schriftart</div>
          @for (value of inscription.typefaces; track value) {
            <div>
              <span *inscFieldValue>{{ value }}</span>
            </div>
          }
        </div>
        <div inscDataField [editorialState]="inscription.language_editorial_state">
          <div *inscFieldLabel>Sprache</div>
          <div *inscFieldValue>{{inscription.language}}</div>
        </div>
        <div inscDataField>
          <div *inscFieldLabel>Sprachliche Form</div>
          @if (inscription.verses) {
            <span *inscFieldValue>Verse</span>
          }
          @if (inscription.prose) {
            <span *inscFieldValue>Prosa</span>
          }
        </div>
        <div inscDataField>
          <div *inscFieldLabel>Beschreibung</div>
          <div *inscFieldValue>{{inscription.description}}</div>
        </div>
      </insc-data-group>
    </div>

    <div class="section">
      <div class="section-title">Datierung (wenn abweichend vom Inschriftenträger)</div>
      <div class="section-content">
        @for (dating of inscription.datings; track dating) {
          <insc-dating-view class="view" [dating]="dating"></insc-dating-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Literatur</div>
      <div class="section-content">
        @for (litRef of inscription.literature_references; track litRef) {
          <insc-literaturereference-view class="view" [literatureReference]="litRef"></insc-literaturereference-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Kommentare</div>
      <div class="section-content">
        @for (comment of inscription.comments; track comment) {
          <insc-comment-view class="view" [comment]="comment"></insc-comment-view>
        }
      </div>
    </div>
  </div>

</div>
